<template>
  <div class="mb-4" v-for="(attachment, idx) in structure" :key="attachment.id ?? attachment.key">
    <img class="mb-2 max-h-48" :src="attachment.entity.file.url" alt="" v-if="attachment?.entity?.file?.url" />
    <div class="flex items-center mt-2 gap-2 list">
      <FileUploader class="input-row mb-0" v-model="attachment.entity.file" :error="errors[`${idx}.entity.file_id`]" />
      <button class="btn btn-transparent text-system-m"
              v-if="!isFirst(attachment)" @click="up(attachment)"><span class="icon-arrow-up"></span></button>
      <button class="btn btn-transparent text-system-m"
              v-if="!isLast(attachment)" @click="down(attachment)"><span class="icon-arrow-down"></span></button>
      <button class="btn btn-transparent w-auto px-2 text-system-m" @click="remove(attachment)">Удалить
      </button>
    </div>
  </div>
  <div>
    <button type="button" class="btn text-border text-system-m py-2.5" @click="addAttachment">Добавить изображение</button>
  </div>
</template>

<script setup>
import FileUploader from '@/components/common/FileUploader'
import useStructure from '~/composables/useStructure'

const props = defineProps(['modelValue', 'errors'])
const emit = defineEmits(['update:questions'])

const structure = computed({
  get: () => props.modelValue,
  set: value => emit('update:questions', value)
})

function addAttachment () {
  unref(structure).push({
    key: Date.now(),
    entity: {},
    entity_type: 'image_attachment'
  })
}

const {
  isFirst,
  isLast,
  up,
  down,
  remove
} = useStructure(structure)
</script>

<style scoped lang="scss">
.list {
  @media (max-width: 920px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
