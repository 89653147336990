<template>
  <div class="school-edit-card mb-5" v-for="(question, i) in structure" :key="question.id ?? question.key">
    <div class="flex gap-4 list">
      <BaseInput v-model="question.name" :error="errors[`questions.${i}.name`]"
                 type="text"
                 :label="'Вопрос №' + (i + 1)" placeholder="Введите текст вопроса"/>
      <BaseInput v-model="question.type" :error="errors[`questions.${i}.type`]" :options="questionTypes"
                 class="w-64 flex-shrink-0" style="width: 16rem; margin-top: 0;" type="select" :search="true"
                 label="Тип вопроса" placeholder="Выбери тип вопроса" />
    </div>
    <div class="flex justify-between items-center" v-if="question.type === 'text'">
      <label class="checkbox-label mb-0">
        <input type="checkbox" class="appearance-none" v-model="question.exist_right_answer" :value="true">
        <span class="check icon-checked"></span>
        Правильные ответы
      </label>
      <p v-if="question.exist_right_answer" class="text-system-m text-border uppercase">отметьте правильные ответы:</p>
    </div>
    <QuestionAttachments v-model="question.attachments" :errors="subsetErrors(errors, `questions.${i}.attachments`)" />
    <div>
      <hr class="border-secondary mt-5 mb-3">
      <template v-if="['one', 'many'].includes(question.type)">
        <div class="space-y-1">
          <div v-for="(answer, answerIndex) in question.answers" :key="answer.id ?? answer.key"
               class="flex justify-between items-center">
            <span class="check icon-checked mb-px" :class="{'rounded-full': question.type === 'one'}"></span>
            <BaseInput v-model="answer.name" :error="errors[`questions.${i}.answers.${answerIndex}.name`]"
                       type="text" placeholder="Вариант" class="input-text w-1/2 mr-auto"/>
            <label class="checkbox-label mb-0">
              <input type="radio" class="appearance-none" :name="`a${question.id ?? question.key}`"
                     v-model="question.right_idx" :value="answerIndex" v-if="question.type === 'one'">
              <input type="checkbox" class="appearance-none" :name="`a${question.id ?? question.key}`"
                     v-model="answer.right" :value="true" v-else>
              <span class="check icon-checked test-check"></span>
            </label>
          </div>
          <div class="flex items-center">
            <span class="check icon-checked mb-px" :class="{'rounded-full': question.type === 'one'}"></span>
            <button class="btn text-border text-system-m py-2.5" type="button" @click="addAnswer(question)">Добавить
              вариант
            </button>
          </div>
        </div>
        <BaseInput v-model="question.explain" :error="errors[`questions.${i}.explain`]"
                   type="text" class="mt-4"
                   label="Пояснение к правильному ответу" placeholder="Необязательно"/>
      </template>
      <template v-else-if="question.type === 'compare'">
        <p class="text-border text-system-m my-4">Введите название вариантов и соответствующие им верные ответы</p>
        <div class="space-y-1">
          <div v-for="(answer, answerIndex) in question.answers" :key="answer.id ?? answer.key"
               class="flex justify-between">
            <BaseInput v-model="answer.name" :error="errors[`questions.${i}.answers.${answerIndex}.name`]"
                       type="text" placeholder="Вариант" class="w-1/2 mr-auto"/>
            <span class="text-light font-bold mx-4 mt-3 self-start">—</span>
            <BaseInput v-model="answer.right_comparable"
                       :error="errors[`questions.${i}.answers.${answerIndex}.right_comparable`]"
                       type="text" placeholder="Вариант" class="w-1/2 mr-auto"/>
          </div>
          <button class="btn text-border text-system-m py-2.5" type="button" @click="addAnswer(question)">Добавить
            вариант
          </button>
        </div>
        <BaseInput v-model="question.explain" :error="errors[`questions.${i}.explain`]"
                   type="text" class="mt-4"
                   label="Пояснение к правильному ответу" placeholder="Необязательно"/>
      </template>
      <BaseInput v-else-if="question.type === 'text' && question.exist_right_answer"
                 v-model="question.right_text"
                 :error="errors[`questions.${i}.right_text`]"
                 type="text" class="mt-4" placeholder="Необязательно"
                 label="Правильный ответ (появляется после отправки ответа)"/>
    </div>
    <div class="flex mt-2 gap-2 justify-end">
      <button class="btn btn-transparent text-system-m"
              v-if="!isFirst(question)" @click="up(question)"><span class="icon-arrow-up"></span></button>
      <button class="btn btn-transparent text-system-m"
              v-if="!isLast(question)" @click="down(question)"><span class="icon-arrow-down"></span></button>
      <button class="btn btn-transparent w-auto px-2 text-system-m"
              v-if="structure.length > 1" @click="remove(question)">Удалить
      </button>
      <button class="btn btn-transparent w-auto px-2 text-system-m"
              @click="copy(question)">Копировать
      </button>
    </div>
  </div>
  <div class="">
    <button type="button" class="btn text-border text-system-m py-2.5" @click="addQuestion">Добавить вопрос</button>
  </div>
</template>

<script setup>
import BaseInput from '@/components/common/Input'
import useStructure from '~/composables/useStructure'
import { questionTypes } from '@/data/questionTypes'
import QuestionAttachments from '@/components/mentor/materials/QuestionAttachments'
import subsetErrors from '~/utils/subsetErrors'

const props = defineProps(['questions', 'errors'])
const emit = defineEmits(['update:questions'])

const structure = computed({
  get: () => props.questions,
  set: value => emit('update:questions', value)
})

function addQuestion () {
  unref(structure).push({
    key: Date.now(),
    attachments: [],
    answers: []
  })
}

function addAnswer (question) {
  unref(question).answers.push({
    key: Date.now()
  })
}

const {
  isFirst,
  isLast,
  up,
  down,
  remove,
  copy
} = useStructure(structure)
</script>

<style scoped lang="scss">
.list {
  @media (max-width: 920px) {
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
