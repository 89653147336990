export const questionTypes = [
  {
    id: 'one',
    name: 'Один вариант ответа'
  },
  {
    id: 'many',
    name: 'Несколько ответов'
  },
  {
    id: 'compare',
    name: 'Соответствие'
  },
  {
    id: 'text',
    name: 'Развернутый ответ'
  }
]
