<template>
  <main class="materials-styles">
    <div class="horiz-container">
      <div class="flex items-center gap-3 text-light mb-10 breadcrumbs">
        <NuxtLink :to="{name: 'materials'}" class="link-subnav active">Мои материалы</NuxtLink>
        <span class="icon-chevron-right"></span>
        <NuxtLink :to="$route" class="link-subnav">
          {{ form.id ? `Редактирование теста: "${form.name}"` : 'Создание теста' }}
        </NuxtLink>
      </div>
      <BaseInput v-model="form.name" :error="errors.name"
                 type="text" class="input-text input-h2 bg-transparent mb-4" style="margin-bottom: 1rem;" placeholder="Введите название"/>
      <TestQuestions v-model:questions="form.questions" :errors="errors"/>

      <div class="flex justify-end gap-4 mt-8 edit-actions">
        <button type="button" class="btn btn-secondary text-system-btn px-4" @click="remove" v-if="form.id">
          Удалить
        </button>
        <button class="btn btn-lg btn-reversed" @click="submit">Сохранить</button>
      </div>
    </div>
  </main>
  <ClientOnly><PromptModal @provide="prompt = $event"/></ClientOnly>
</template>

<script setup>
import useForm from '~/composables/useForm'
import { getTest, storeTest, updateTest, deleteLesson } from '@/api/materials'
import TestQuestions from '@/components/mentor/materials/TestQuestions'
import BaseInput from '@/components/common/BaseInput'
import PromptModal from '@/components/common/PromptModal'

const router = useRouter()
const route = useRoute()
const prompt = ref(null)

const {
  form,
  errors,
  loading,
  submit
} = useForm(data => {
  data.questions = data.questions.map(question => {
    question.exist_right_answer = question.type !== 'text' ? true : !!question.exist_right_answer
    return question
  })
  return (form.value.id
    ? updateTest(route.params.testId, data)
    : storeTest(route.params.sectionId, data))
}, () => {
  router.push({
    name: 'materials'
  })
})

useHead({
  title: computed(() => form.value?.id ? `Редактирование теста - ${form.value?.name}` : 'Создание теста')
})

async function remove () {
  const accept = await prompt.value({
    title: `Удалить урок ${form.value.name}`,
    description: 'Вы уверены, что хотите удалить этот урок? Это необратимое действие.'
  })
  if (accept) {
    await deleteLesson(route.params.testId)
    await router.replace({ name: 'materials' })
  }
}

const { data: test } = await useAsyncData(async () => route.params.testId ? await getTest(route.params.testId) : {})
if (!test.value) throw createNotFoundError()

watchEffect(() => {
  form.value = test.value?.questions ? test.value : {
    questions: [
      {
        key: Date.now(),
        type: 'one',
        attachments: [],
        answers: []
      }
    ]
  }
})
</script>

<style lang="scss">
@import "@/assets/css/materials";
</style>
